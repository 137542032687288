exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-agri-produce-jsx": () => import("./../../../src/pages/agri-produce.jsx" /* webpackChunkName: "component---src-pages-agri-produce-jsx" */),
  "component---src-pages-all-vacancies-jsx": () => import("./../../../src/pages/all-vacancies.jsx" /* webpackChunkName: "component---src-pages-all-vacancies-jsx" */),
  "component---src-pages-analyst-coverage-jsx": () => import("./../../../src/pages/analyst-coverage.jsx" /* webpackChunkName: "component---src-pages-analyst-coverage-jsx" */),
  "component---src-pages-careers-page-jsx": () => import("./../../../src/pages/careers-page.jsx" /* webpackChunkName: "component---src-pages-careers-page-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-corporate-event-jsx": () => import("./../../../src/pages/corporate-event.jsx" /* webpackChunkName: "component---src-pages-corporate-event-jsx" */),
  "component---src-pages-corporate-information-jsx": () => import("./../../../src/pages/corporate-information.jsx" /* webpackChunkName: "component---src-pages-corporate-information-jsx" */),
  "component---src-pages-crop-solutions-jsx": () => import("./../../../src/pages/crop-solutions.jsx" /* webpackChunkName: "component---src-pages-crop-solutions-jsx" */),
  "component---src-pages-diary-products-jsx": () => import("./../../../src/pages/diary-products.jsx" /* webpackChunkName: "component---src-pages-diary-products-jsx" */),
  "component---src-pages-dividend-information-jsx": () => import("./../../../src/pages/dividend-information.jsx" /* webpackChunkName: "component---src-pages-dividend-information-jsx" */),
  "component---src-pages-financial-highlights-jsx": () => import("./../../../src/pages/financial-highlights.jsx" /* webpackChunkName: "component---src-pages-financial-highlights-jsx" */),
  "component---src-pages-fresh-produce-jsx": () => import("./../../../src/pages/fresh-produce.jsx" /* webpackChunkName: "component---src-pages-fresh-produce-jsx" */),
  "component---src-pages-health-care-jsx": () => import("./../../../src/pages/health-care.jsx" /* webpackChunkName: "component---src-pages-health-care-jsx" */),
  "component---src-pages-home-page-jsx": () => import("./../../../src/pages/home-page.jsx" /* webpackChunkName: "component---src-pages-home-page-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-solutions-jsx": () => import("./../../../src/pages/industrial-solutions.jsx" /* webpackChunkName: "component---src-pages-industrial-solutions-jsx" */),
  "component---src-pages-investor-desk-jsx": () => import("./../../../src/pages/investor-desk.jsx" /* webpackChunkName: "component---src-pages-investor-desk-jsx" */),
  "component---src-pages-investor-information-jsx": () => import("./../../../src/pages/investor-information.jsx" /* webpackChunkName: "component---src-pages-investor-information-jsx" */),
  "component---src-pages-livestock-solutions-jsx": () => import("./../../../src/pages/livestock-solutions.jsx" /* webpackChunkName: "component---src-pages-livestock-solutions-jsx" */),
  "component---src-pages-market-capitalization-jsx": () => import("./../../../src/pages/market-capitalization.jsx" /* webpackChunkName: "component---src-pages-market-capitalization-jsx" */),
  "component---src-pages-media-room-jsx": () => import("./../../../src/pages/media-room.jsx" /* webpackChunkName: "component---src-pages-media-room-jsx" */),
  "component---src-pages-our-brands-jsx": () => import("./../../../src/pages/our-brands.jsx" /* webpackChunkName: "component---src-pages-our-brands-jsx" */),
  "component---src-pages-our-segments-jsx": () => import("./../../../src/pages/our-segments.jsx" /* webpackChunkName: "component---src-pages-our-segments-jsx" */),
  "component---src-pages-pages-template-news-template-jsx": () => import("./../../../src/pages/pages-template/NewsTemplate.jsx" /* webpackChunkName: "component---src-pages-pages-template-news-template-jsx" */),
  "component---src-pages-press-release-jsx": () => import("./../../../src/pages/press-release.jsx" /* webpackChunkName: "component---src-pages-press-release-jsx" */),
  "component---src-pages-quality-rice-jsx": () => import("./../../../src/pages/quality-rice.jsx" /* webpackChunkName: "component---src-pages-quality-rice-jsx" */),
  "component---src-pages-reports-and-presentation-jsx": () => import("./../../../src/pages/reports-and-presentation.jsx" /* webpackChunkName: "component---src-pages-reports-and-presentation-jsx" */),
  "component---src-pages-share-information-jsx": () => import("./../../../src/pages/share-information.jsx" /* webpackChunkName: "component---src-pages-share-information-jsx" */),
  "component---src-pages-sunshine-jsx": () => import("./../../../src/pages/sunshine.jsx" /* webpackChunkName: "component---src-pages-sunshine-jsx" */),
  "component---src-pages-sustainability-jsx": () => import("./../../../src/pages/sustainability.jsx" /* webpackChunkName: "component---src-pages-sustainability-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */)
}

