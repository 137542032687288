import React from "react";
import "./footer.css";
import FooterLogo from "../images/footer-logo.png";
import SocialIcons from "../images/group-17.svg";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";

export default function Footer() {
  return (
    <div className="footer">
      <div className="foot-footer-3">
        <div className="foot-debits">
          <div className="foot-social-links-parent">
            <div className="foot-logo-link">
              <a href="/" className="foot-logo-link">
                <img
                  className="foot-cic-logo-white-icon"
                  alt="CIC Logo"
                  src={FooterLogo}
                />
              </a>
            </div>
            <div className="foot-content49">
              <div className="foot-container7">
                <div className="foot-address">Contact us:</div>
                <div className="foot-div">
                  <p className="foot-the-board-plays">
                    <a href="mailto:cicho@cic.lk">cicho@cic.lk</a> |{" "}
                    <a href="mailto:info@cic.lk">info@cic.lk</a>
                  </p>
                  <p className="foot-the-board-plays">
                    <a href="tel:+94112328421">+94 112328421</a>
                  </p>
                  <p className="foot-the-board-plays">
                    <a href="tel:+94112328426">+94 112328426</a>
                  </p>
                  <p className="foot-the-board-plays">
                    <a href="tel:+94112359359">+94 112359359</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="foot-content48">
              <div className="foot-address">Our address:</div>
              <div className="foot-level-1-12-container1">
                <p className="foot-the-board-plays">
                  <div style={{ marginTop: "-13px" }}>
                    <a
                      href="https://maps.app.goo.gl/AL7n2YJdQ5xxz86D7"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ lineHeight: 1.8 }}
                    >
                      199, Kew Road, <br /> Colombo 2.
                    </a>
                  </div>
                </p>
              </div>
            </div>
            <div className="foot-content47">
              <div className="foot-address">Our social:</div>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/cicholdings"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.instagram.com/cic_holdings/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/cic-holdings-plc/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="foot-credits">
          <div className="foot-divider11" />
          <div className="foot-row6">
            <div className="foot-web-design-by">
              Web Design by <a href="https://www.3cs.lk/">3CS</a> | Copyright ©
              2024 CIC Holdings PLC
            </div>
            <div className="foot-footer-links">
              <a href="#" className="foot-privacy-policy">{`T&C of Sale`}</a>
              <a href="#" className="foot-privacy-policy">
                Cookie Policy
              </a>
              <a href="#" className="foot-privacy-policy">
                Privacy Policy
              </a>
              <a href="#" className="foot-privacy-policy">
                Sitemap
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
