import React, { useState, useEffect } from "react";
import CiCLogo from "../images/CiC-Logo-1.webp";
import HamburgerIcon from "../images/menu-icon.png";
import CloseButtonIcon from "../images/close_btn.svg";
import "./header.css";
import { Phone } from "lucide-react";
import Aos from "aos";
import "aos/dist/aos.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { FaArrowDown } from "react-icons/fa";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  //====================== Home Transition ========================//
  const [homeTransition, setHomeTransition] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const handleHomeTransition = (e, prop) => {
    let xPos = 0;

    if (typeof window !== "undefined") {
      if (window.innerWidth < 1100) {
        xPos = e.clientX;
      } else {
        xPos = e.clientX - 20;
      }
      const yPos = e.clientY;

      document.documentElement.style.setProperty("--xPos", `${xPos}px`);
      document.documentElement.style.setProperty("--yPos", `${yPos}px`);

      if (prop === "home") {
        if (currentPath === "/") {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, "", "/");
          setHomeTransition(true);
          setTimeout(() => {
            navigate("/");
          }, 700);
        }
      } else if (prop === "about-us") {
        if (currentPath === "/about-us/") {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, "", "/about-us/");
          setHomeTransition(true);
          setTimeout(() => {
            navigate("/about-us/");
          }, 700);
        }
      } else if (prop === "our-segments") {
        if (currentPath === "/our-segments/") {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, "", "/our-segments/");
          setHomeTransition(true);
          setTimeout(() => {
            navigate("/our-segments/");
          }, 700);
        }
      } else if (prop === "our-brands") {
        if (currentPath === "/our-brands/") {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, "", "/our-brands/");
          setHomeTransition(true);
          setTimeout(() => {
            navigate("/our-brands/");
          }, 700);
        }
      } else if (prop === "media-room") {
        if (currentPath === "/media-room/") {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, "", "/media-room/");
          setHomeTransition(true);
          setTimeout(() => {
            navigate("/media-room/");
          }, 700);
        }
      } else if (prop === "investor-desk") {
        if (currentPath === "/investor-desk/") {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, "", "/investor-desk/");
          setHomeTransition(true);
          setTimeout(() => {
            navigate("/investor-desk/");
          }, 700);
        }
      } else if (prop === "sustainability") {
        if (currentPath === "/sustainability/") {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, "", "/sustainability/");
          setHomeTransition(true);
          setTimeout(() => {
            navigate("/sustainability/");
          }, 700);
        }
      } else if (prop === "contact-us") {
        if (currentPath === "/contact-us/") {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, "", "/contact-us/");
          setHomeTransition(true);
          setTimeout(() => {
            navigate("/contact-us/");
          }, 700);
        }
      } else if (prop === "careers-page") {
        if (currentPath === "/careers-page") {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, "", "/careers-page");
          setHomeTransition(true);
          setTimeout(() => {
            navigate("/careers-page");
          }, 700);
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (homeTransition) {
        setHomeTransition(false);
      }
    }, 1500);
  }, [homeTransition]);

  // =================== End of Home Transition ==================== //

  const [navPadding, setNavPadding] = useState("10px 5px");
  const [navBackground, setNavBackground] = useState("rgba(255, 255, 255, 0)");
  const [menuColor, setMenuColor] = useState("#ffffff");
  const [showNav, setShowNav] = useState(false);
  const [showArrow, setShowArrow] = useState(true);

  const handleScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 50) {
        setNavPadding("3px 1px");
        setNavBackground("rgba(255, 255, 255, 1)");
        setMenuColor("#404040");
        setShowNav(true);
        setShowArrow(false);
      } else {
        setNavPadding("10px 5px");
        setNavBackground("rgba(255, 255, 255, 0)");
        setMenuColor("#ffffff");
        setShowNav(false);
        setShowArrow(true);
      }
    }
  };

  const scrollToNavigation = () => {
    if (typeof window !== "undefined") {
      window.scrollBy({ top: 100, left: 0, behavior: "smooth" }); // Scroll down slightly
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    Aos.init({ duration: 2000, easing: "ease-in-out" });
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && isMenuOpen) {
      window.scrollTo(0, 0);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdown-container") &&
        !event.target.closest(".dropdown-btn")
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className="main-content">
      {showArrow && (
        <div className="scroll-arrow" onClick={scrollToNavigation}>
          <FaArrowDown size={30} color="#fff" />
        </div>
      )}

      {showNav && (
        <nav
          className="nav-2"
          style={{ padding: navPadding, backgroundColor: navBackground }}
        >
          {homeTransition ? (
            <div className="main-content-animation"></div>
          ) : null}
          <div className="logo-2">
            <a href="/">
              <img
                data-aos="slide-right"
                data-aos-duration="500"
                className="LogoC1"
                src={CiCLogo}
                alt="Logo"
              />
            </a>
          </div>

          {isMenuOpen && (
            <>
              <div className="close-button" onClick={handleMobileMenuToggle}>
                <img src={CloseButtonIcon} alt="Close Button" />
              </div>
            </>
          )}

          <ul className={`menu ${isMenuOpen ? "open" : ""}`}>
            <li
              className="lia testClass"
              data-aos="fade-down"
              data-aos-duration="250"
              data-aos-delay="200"
            >
              {/* <AniLink paintDrip to="/" hex="#001033">HOME</AniLink> */}
              <Link
                to="/"
                activeClassName="active"
                style={{ color: menuColor }}
                onClick={(e) => handleHomeTransition(e, "home")}
              >
                HOME
              </Link>
            </li>

            <li
              data-aos="fade-down"
              data-aos-duration="250"
              data-aos-delay="450"
            >
              {/* <AniLink paintDrip to="/about-us" hex="#001033">ABOUT US</AniLink> */}
              <Link
                to="/about-us"
                activeClassName="active"
                style={{ color: menuColor }}
                onClick={(e) => handleHomeTransition(e, "about-us")}
              >
                ABOUT US
              </Link>
            </li>

            <li
              data-aos="fade-down"
              data-aos-duration="250"
              data-aos-delay="600"
            >
              <Link
                // paintDrip
                // hex="#001033"
                style={{ color: menuColor }}
                to="/our-segments/"
                activeClassName="active"
                onClick={(e) => handleHomeTransition(e, "our-segments")}
              >
                OUR SEGMENTS
              </Link>
            </li>

            <li
              data-aos="fade-down"
              data-aos-duration="250"
              data-aos-delay="700"
            >
              <Link
                // paintDrip
                // hex="#001033"
                style={{ color: menuColor }}
                to="/our-brands/"
                activeClassName="active"
                onClick={(e) => handleHomeTransition(e, "our-brands")}
              >
                OUR BRANDS
              </Link>
            </li>

            <li
              data-aos="fade-down"
              data-aos-duration="250"
              data-aos-delay="950"
            >
              <Link
                // paintDrip
                // hex="#001033"
                style={{ color: menuColor }}
                to="/sustainability/"
                activeClassName="active"
                onClick={(e) => handleHomeTransition(e, "sustainability")}
              >
                SUSTAINABILITY
              </Link>
            </li>

            <li
              data-aos="fade-down"
              data-aos-duration="250"
              data-aos-delay="1200"
            >
              <Link
                // paintDrip
                // hex="#001033"
                style={{ color: menuColor }}
                to="/media-room/"
                activeClassName="active"
                onClick={(e) => handleHomeTransition(e, "media-room")}
              >
                MEDIA ROOM
              </Link>
            </li>

            <li
              data-aos="fade-down"
              data-aos-duration="250"
              data-aos-delay="1450"
            >
              <Link
                // paintDrip
                // hex="#001033"
                style={{ color: menuColor }}
                to="/investor-desk/"
                activeClassName="active"
                onClick={(e) => handleHomeTransition(e, "investor-desk")}
              >
                INVESTOR DESK
              </Link>
            </li>

            <li
              data-aos="fade-down"
              data-aos-duration="250"
              data-aos-delay="1700"
            >
              {/* <AniLink paintDrip to="/about-us" hex="#001033">ABOUT US</AniLink> */}
              <Link
                to="/careers-page/"
                activeClassName="active"
                style={{ color: menuColor }}
                onClick={(e) => handleHomeTransition(e, "careers-page")}
              >
                CAREERS
              </Link>
            </li>

            <li
              data-aos="fade-down"
              data-aos-duration="250"
              data-aos-delay="1750"
            >
              <Link
                // paintDrip
                // hex="#001033"
                style={{ color: menuColor }}
                to="/contact-us/"
                activeClassName="active"
                onClick={(e) => handleHomeTransition(e, "contact-us")}
              >
                <Phone />
              </Link>
            </li>

            <li className="search-box">
              <button className="btn-search">
                <i className="fas fa-search"></i>
              </button>
              <input
                type="text"
                className="input-search"
                placeholder="Type to Search..."
              />
            </li>
          </ul>

          <div className="hamburger-img">
            <img
              className="mobileIcon"
              onClick={handleMobileMenuToggle}
              src={HamburgerIcon}
              alt="Menu"
            />
          </div>
        </nav>
      )}
    </div>
  );
};

export default Header;
